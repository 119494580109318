<template>
  <CDataTableDev
    :columnFilter="false"
    :hover="hover"
    :striped="striped"
    :border="border"
    :small="small"
    :fixed="fixed"
    :items="items"
    :fields="fields"
    column-filter
    items-per-page-select
    :items-per-page="10"
    sorter
    pagination
    table-filter
    cleaner
    :actions="actions"
    :buttonEdit="buttonEdit"
    :buttonDelete="buttonDelete"
    :withActions="withActions"
    :myButtons="myButtons"
    @mtd_action_mybutton="mtd_action_mybutton"
    @action_edit="action_edit"
    @action_delete="action_delete"
  >
    <template #status="{ item }">
      <td>
        <CBadge :color="getBadge(item.status)">
          {{ item.status }}
        </CBadge>
      </td>
    </template>
    <template #show_details="{ item, index }">
      <td class="py-2">
        <CButton
          color="primary"
          variant="outline"
          square
          size="sm"
          @click="toggleDetails(item, index)"
        >
          {{ Boolean(item._toggled) ? "Hide" : "Show" }}
        </CButton>
      </td>
    </template>
    <template #details="{ item }">
      <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
        <CCardBody>
          <CMedia :aside-image-props="{ height: 102 }">
            <h4>
              {{ item.username }}
            </h4>
            <p class="text-muted">User since: {{ item.registered }}</p>
            <CButton size="sm" color="info" class=""> User Settings </CButton>
            <CButton size="sm" color="danger" class="ml-1"> Delete </CButton>
          </CMedia>
        </CCardBody>
      </CCollapse>
    </template>
  </CDataTableDev>
</template>

<script>
import CDataTableDev from "./CDataTable.vue";
export default {
  name: "AdvancedTables",
  components: { CDataTableDev },
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["username", "registered", "role", "status"];
      },
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    actions: Boolean,
    buttonEdit: Boolean,
    buttonDelete: Boolean,
    withActions:String,
    myButtons:Array,
  },
  data() {
    return {
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case "Activo":
          return "success";
        case "Inactivo":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
    toggleDetails(item) {
      this.$set(this.items[item.Id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    /** */
    mtd_action_mybutton: function (row) {
      this.$emit('mtd_action_mybutton',row);
    },
    action_edit: function(id){      
      this.$emit('action_edit',id);
    },
    action_delete: function(item){      
      this.$emit('action_delete',item);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>